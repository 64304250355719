<template>
  <v-dialog persistent v-model="bDialog" max-width="500">
    <template #activator="{ on: dialog }">
      <v-list
        color="transparent"
        flat
        max-width="200px"
        class="py-0 fill-height"
      >
        <v-menu
          open-on-hover
          offset-y
          bottom
          rounded="t-0 b-lg"
          close-delay="500"
        >
          <template #activator="{ on: menu }">
            <v-list-item
              class="fill-height"
              dense
              v-on="{ ...menu, ...dialog }"
            >
              <name-with-avatar
                :name="title"
                :path="avatar"
                fill-height
                hide-avatar-on-mobile
              >
                <template #subtitle v-if="branch.firm">
                  <div class="text-caption" v-text="branch.firm.name" />
                </template>
              </name-with-avatar>
            </v-list-item>
          </template>

          <v-list dense nav>
            <v-subheader v-text="title" />
            <template v-for="branchItem in branchList">
              <v-list-item
                @click="setBranch(branchItem.id)"
                :key="`branch-item-${branchItem.id}`"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="branchItem.firm.name" />
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item @click="openSettingsDialog">
              <v-list-item-icon><icon-settings /></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t('company.settings')" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-list-item v-on="dialog" v-else>
          <name-with-avatar :name="title" :path="avatar">
            <template #subtitle v-if="branch.name">
              <div class="text-caption text-truncate" v-text="branch.name" />
            </template>
          </name-with-avatar>
        </v-list-item> -->
      </v-list>
      <company-settings-dialog v-model="bSettingsDialog" />
    </template>

    <v-card :loading="loading">
      <v-list class="active" v-if="company.id">
        <v-list-item>
          <v-list-item-avatar color="my-1 mr-2">
            <v-img v-if="avatar" :src="avatar" :alt="company.firm.name" />
            <icon-camera outlined size="1.8rem" v-else />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="company.firm.name" />
            <v-list-item-subtitle v-text="branch.name" />
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-text v-if="userIsAdmin">
        <v-text-field
          :placeholder="$t('search.company')"
          :label="$t('search.company')"
          v-model="sSearch"
          ref="searchField"
        ></v-text-field>
      </v-card-text>

      <v-card-text v-if="obCompanyCollection.length">
        <v-list rounded color="transparent">
          <template v-for="(item, index) in obCompanyCollection.getModels()">
            <v-list-item
              @click="setCompany(item)"
              :key="`companies-list-item-${index}`"
              :class="{ 'v-list-item--active': company.id == item.id }"
            >
              <v-list-item-avatar color="grey lighten-3">
                <v-img
                  :src="item.preview_image"
                  :alt="item.firm.name"
                  v-if="item.preview_image"
                />
                <icon-camera outlined size="1.8rem" v-else />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.firm.name" />
                <v-list-item-subtitle v-text="item.firm.email" />
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          depressed
          :disabled="!closable"
          @click="bDialog = false"
          color="primary"
        >
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import type { CompanyData } from "@planetadeleste/vue-mc-gw";
import { CompanyCollection } from "@planetadeleste/vue-mc-gw";
import { Component, Vue, Watch } from "vue-property-decorator";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import { AppModule } from "@/store/app";
import CompanySettingsDialog from "@/modules/companies/components/CompanySettingsDialog.vue";
import { AuthModule } from "@/store/auth";
import type { DebounceFunction } from "@/plugins/helpers";

@Component({
  components: { NameWithAvatar, CompanySettingsDialog },
})
export default class CompanyPicker extends Vue {
  bDialog = false;
  bSettingsDialog = false;
  sSearch = "";
  fnDebounceSearch!: DebounceFunction;
  obCompanyCollection = new CompanyCollection();

  @Watch("sSearch")
  onSearch() {
    this.fnDebounceSearch();
  }

  @Watch("bDialog")
  onClose(bVal: boolean) {
    if (!bVal && this.userIsAdmin) {
      this.sSearch = "";
      this.obCompanyCollection.clear();
    } else if (bVal) {
      this.search();
    }
  }

  created() {
    this.fnDebounceSearch = this.$_.debounce(this.search, 500);
  }

  mounted() {
    this.$nextTick(async () => {
      await this.search();

      if (!this.obCompanyCollection.length) {
        if (this.$route.name && this.$route.name !== "companies.create") {
          await this.$router.push({ name: "companies.create" });
        }

        return;
      }

      if (!localStorage.getItem("cid")) {
        this.bDialog = true;
      }
    });
  }

  async search() {
    this.obCompanyCollection.clear();
    this.obCompanyCollection.page(1);

    if (this.userIsCompany) {
      this.obCompanyCollection.byUser(this.user.id);
    }

    if (this.sSearch.length) {
      this.obCompanyCollection.filterBy({ search: this.sSearch });
    } else {
      this.obCompanyCollection.filterBy({ take: 5 });
    }

    await this.obCompanyCollection.fetch();
  }

  async fetchCategories() {
    await AppModule.loadCategories();
  }

  unsetCompany() {
    AppModule.unsetCompany();
    this.fetchCategories();
  }

  async setCompany(obCompany: CompanyData) {
    AppModule.pickCompany(obCompany);
    await this.fetchCategories();
    this.bDialog = false;
  }

  setBranch(iBranchID: number) {
    AppModule.pickBranch(iBranchID);
  }

  openSettingsDialog() {
    this.bSettingsDialog = true;
  }

  get loading() {
    return this.obCompanyCollection.loading || AppModule.categories.loading;
  }

  get user() {
    return AuthModule.user;
  }

  get userIsCompany() {
    return AuthModule.isCompany;
  }

  get userIsAdmin() {
    return AuthModule.isAdmin;
  }

  get company() {
    return AppModule.company;
  }

  get branch() {
    return AppModule.branch;
  }

  get branchList() {
    if (
      !this.company ||
      !this.company.branches ||
      this.company.branches.length < 2
    ) {
      return [];
    }

    return this.$_.filter(this.company.branches, (obBranch) => {
      return obBranch.id !== this.branch.id;
    });
  }

  get title() {
    return this.company.id ? this.company.firm.name : this.$t("pick.company");
  }

  get avatar() {
    return this.company.preview_image;
  }

  get closable() {
    return !!this.company.id || !this.obCompanyCollection.length;
  }
}
</script>
