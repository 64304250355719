import { Component, Vue } from "vue-property-decorator";
import type { ValidationObserver } from "vee-validate";
import { LoadingModule } from "@/store/loading";
import type { ResponseError } from "@bit/planetadeleste.shopaholic.types.api";
import type VueI18n from "vue-i18n";
import type { Result } from "vue-mc";
import { dotcase } from "@/plugins/helpers";
import type { ToastContent } from "vue-toastification/dist/types/src/types";

@Component
export default class GlobalMixin extends Vue {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };
  responseErrors: ResponseError = {
    error: [],
  };

  get isLoading() {
    return LoadingModule.getLoading;
  }

  mounted() {
    this.$refs.observer;
  }

  loaded() {
    LoadingModule.loaded();
  }

  loading(attach?: string) {
    LoadingModule.loading(attach);
  }

  setLoading(close = false, attach?: string) {
    if (close) {
      this.loaded();
    } else {
      this.loading(attach);
    }
  }

  processError(error: any) {
    this.syncErrors(error);
    let message = this.getError("error", "Network error");

    if (message === "invalid_credentials") {
      message = "Usuario/Email o clave incorrectos";
    }

    this.setLoading(true);
    if (message) {
      this.flashError(message);
    }
  }

  flashSuccess(message: ToastContent) {
    this.$toast.success(message);
  }

  flashError(message: ToastContent) {
    this.$toast.error(message);
  }

  syncErrors(error: any): boolean {
    if (this.$_.isUndefined(error)) {
      return true;
    }

    if (error instanceof Error) {
      const message: string = error.message;
      if (this.isJSON(message)) {
        return this.syncErrors(JSON.parse(message));
      }

      this.responseErrors.error = [message];
    } else if (
      !this.$_.isUndefined(error.response) &&
      this.$_.has(error, "response") &&
      this.$_.has(error.response, "data")
    ) {
      if (this.$_.has(error.response.data, "errors")) {
        this.responseErrors = error.response.data.errors;
      } else if (this.$_.has(error.response.data, "error")) {
        this.responseErrors.error = [error.response.data.error];
      }
    } else if (this.$_.has(error, "message")) {
      this.responseErrors.error = [error.message];
    } else if (this.$_.has(error, "error")) {
      // @ts-ignore
      this.responseErrors.error = [error.error];
    }

    return this.responseErrors.error.length > 0;
  }

  clearErrors() {
    this.responseErrors = { error: [] };
  }

  hasErrors(sField: string): boolean {
    return this.$_.has(this.responseErrors, sField);
  }

  getError<T>(sField: string, sDefault: T | null = null): T | null {
    const sMessage: T | null | undefined = this.hasErrors(sField)
      ? this.$_.first(this.$_.get(this.responseErrors, sField))
      : sDefault;

    return sMessage ? sMessage : sDefault;
  }

  getErrors(sField: string): Array<any> | null {
    return this.hasErrors(sField)
      ? this.$_.get(this.responseErrors, sField)
      : null;
  }

  isJSON(str: string): boolean {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  }

  tr(key: VueI18n.Path, values?: VueI18n.Values): string {
    const name = this.slugify(key);
    const msg = this.$t(name, values);
    if (this.$_.isString(msg)) {
      return msg;
    }

    return key;
  }

  slugify(key: string): string {
    return dotcase(key);
  }

  evalResponse(response: Result | undefined) {
    if (!response) {
      return;
    }

    if (response.message) {
      if (response.status) {
        this.flashSuccess(response.message);
      } else {
        this.flashError(response.message);
      }
    }
  }
}
