<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid, valid, errors }"
    :disabled="noNest"
    :vid="vid"
    slim
  >
    <v-card :color="color" class="d-flex flex-column" flat height="100%">
      <!-- TITLE -->
      <v-card-title v-if="title" :class="cssTitle">
        {{ title }}
      </v-card-title>

      <!-- TOP ACTIONS -->
      <v-card-actions v-if="!hideTopActions" :class="cssActionTop">
        <!-- @slot Top action left side -->
        <slot
          name="top-actions-left"
          v-bind="{ invalid: isInvalid(errors, invalid), errors }"
        />
        <div class="flex-grow-1" />

        <!-- @slot Top action before buttons -->
        <slot
          name="top-actions"
          v-bind="{ invalid: isInvalid(errors, invalid), errors }"
        />

        <v-btn
          v-if="!hideSaveAction"
          :disabled="
            isInvalid(errors, invalid) || disabledSaveAction || !isDirty
          "
          :loading="loading"
          :small="isMobile"
          color="success"
          v-bind="buttonOptions"
          @click="save"
        >
          {{ $t(saveLabel) }}
        </v-btn>

        <v-btn
          v-if="!hideCancelAction"
          :disabled="loading"
          :small="isMobile"
          v-bind="buttonOptions"
          @click="cancel"
        >
          {{ $t(cancelLabel) }}
        </v-btn>
      </v-card-actions>

      <slide-y-up-transition>
        <v-alert
          v-if="isInvalid(errors, invalid)"
          class="ma-4 gw-form-alert"
          dense
          outlined
          prominent
          text
          type="warning"
        >
          <v-list class="pa-0" color="transparent" dense flat>
            <slide-y-up-transition group>
              <template v-for="(arMessages, sId) in onParseErrors(errors)">
                <v-list-item
                  v-if="!$_.isEmpty(arMessages)"
                  :key="`form.observer.messages.${sId}`"
                  dense
                >
                  <v-list-item-title
                    v-for="(sMessage, sIndex) in arMessages"
                    :key="`form.observer.message.${sId}.${sIndex}`"
                    v-text="sMessage"
                  />
                </v-list-item>
              </template>
            </slide-y-up-transition>
          </v-list>
        </v-alert>
      </slide-y-up-transition>

      <!-- FORM SLOT -->
      <v-card-text class="flex-grow-1 flex-shrink-1 relative">
        <!-- @slot Default slot -->
        <slot
          v-bind="{ reset: resetForm, validate: validateForm, valid, invalid }"
        />
      </v-card-text>

      <!-- BOTTOM ACTIONS -->
      <v-card-actions v-if="!hideBottomActions" :class="cssActionBottom">
        <!-- @slot Bottom actions left side -->
        <slot
          name="bottom-actions-left"
          v-bind="{ invalid: isInvalid(errors, invalid), errors }"
        />
        <div class="flex-grow-1" />

        <!-- @slot Bottom actions before buttons -->
        <slot
          name="bottom-actions"
          v-bind="{
            invalid: isInvalid(errors, invalid),
            errors,
            validate: validateForm,
          }"
        />

        <v-btn
          v-if="!hideSaveAction"
          :disabled="
            isInvalid(errors, invalid) || disabledSaveAction || !isDirty
          "
          :loading="loading"
          :small="isMobile"
          color="success"
          v-bind="buttonOptions"
          @click="save"
        >
          {{ $t(saveLabel) }}
        </v-btn>

        <v-btn
          v-if="!hideCancelAction"
          :disabled="loading"
          :small="isMobile"
          v-bind="buttonOptions"
          @click="cancel"
        >
          {{ $t(cancelLabel) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
</template>

<script lang="ts">
/**
 * Form Observer
 * @displayName FormObserver
 */
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import type { ValidationObserver } from "vee-validate";

import { SlideYUpTransition } from "vue2-transitions";
import { delay, isEmpty, omitBy, uniqueId } from "lodash";

@Component({
  components: { SlideYUpTransition },
})
export default class FormObserver extends Vue {
  /**
   * Hide top actions buttons
   */
  @Prop(Boolean) readonly hideTopActions!: boolean;
  /**
   * Hide bottom actions buttons
   */
  @Prop(Boolean) readonly hideBottomActions!: boolean;
  /**
   * Hide cancel button on actions panels
   */
  @Prop(Boolean) readonly hideCancelAction!: boolean;
  /**
   * Hide save button on actions panels
   */
  @Prop(Boolean) readonly hideSaveAction!: boolean;

  /**
   * Disable/Enable save button
   */
  @Prop(Boolean) readonly disabledSaveAction!: boolean;

  /**
   * Reset form
   */
  @Prop(Boolean) readonly reset!: boolean;

  /**
   * If true, the observer will be excluded from the parent observer validation.
   */
  @Prop(Boolean) readonly noNest!: boolean;

  /**
   * Do not validate on mount
   */
  @Prop({ type: Boolean, default: false }) readonly lazyValidation!: boolean;

  /**
   * Display loading style
   */
  @Prop(Boolean) readonly loading!: boolean;

  /**
   * Form title
   */
  @Prop(String) readonly title!: string;

  /**
   * Save button label. Default is "save"
   */
  @Prop({ type: String, default: "save" }) readonly saveLabel!: string;

  /**
   * Cancel button label. Default is "cancel"
   */
  @Prop({ type: String, default: "cancel" }) readonly cancelLabel!: string;

  /**
   * Form v-card color
   */
  @Prop(String) readonly color!: string;

  /**
   * Global button options for all buttons on action panels
   * @see https://vuetifyjs.com/en/api/v-btn/
   */
  @Prop({ type: Object, default: () => ({ text: true }) })
  readonly buttonOptions!: Record<string, any>;

  /**
   * Css classes for top action buttons
   */
  @Prop(String) readonly cssActionTop!: string;

  /**
   * Css classes for bottom action buttons
   */
  @Prop(String) readonly cssActionBottom!: string;

  /**
   * Css classes for bottom action buttons
   */
  @Prop({ type: String, default: "text-h5 font-weight-light" })
  readonly cssTitle!: string;

  /**
   * Determine if any of form attributes has changes
   */
  @Prop(Boolean) readonly dirty!: boolean;

  /**
   * Check for dirty property
   */
  @Prop(Boolean) readonly checkDirty!: boolean;

  @Ref("observer") readonly form!:
    | InstanceType<typeof ValidationObserver>
    | undefined;

  get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }

  get isDirty() {
    return this.checkDirty ? this.dirty : true;
  }

  get vid() {
    return uniqueId("form_observer");
  }

  @Watch("reset")
  onResetForm(val: boolean) {
    if (val === true) {
      this.resetForm();
      this.$emit("update:reset", false);
    }
  }

  save() {
    if (!this.form) {
      return;
    }

    this.form.validate().then((valid: boolean) => {
      if (valid) {
        this.$emit("save");
      }
    });
  }

  cancel() {
    this.resetForm();
    this.$emit("cancel");
  }

  resetForm() {
    if (this.form) {
      this.form.reset();
    }
  }

  async validateForm(silent: boolean = false) {
    let valid = false;

    if (this.form) {
      valid = await this.form.validate({ silent });
      const sState = valid ? "valid" : "invalid";
      this.$emit(sState);
    }

    return valid;
  }

  onParseErrors(obErrors: Record<string, string[]>) {
    return omitBy(obErrors, isEmpty);
  }

  mounted() {
    this.resetForm();

    if (!this.lazyValidation) {
      delay(() => this.validateForm(true), 2000);
    }

    this.$watch(
      async () => {
        return this.form ? await this.form.validate({ silent: true }) : true;
      },
      (bVal: Promise<boolean>) => {
        bVal.then((bValid) => {
          const sState = bValid ? "valid" : "invalid";
          this.$emit(sState);
        });
      }
    );
  }

  isInvalid(arErrors: Record<string, string[]>, bInvalid: boolean) {
    return bInvalid && !isEmpty(this.onParseErrors(arErrors));
  }
}
</script>
